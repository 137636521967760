import React from "react"
import Layout from "../components/layout"
import ScienceCards from "../components/science-cards/science-cards"
import SEO from "../components/seo"
import ScienceCover from "../images/science-cover.svg"
import DAAP from "../images/science/daap-card.svg"
import DASS from "../images/science/dass-card.svg"
import AIM from "../images/science/aim-card.svg"
import Network from "../images/science/network-card.svg"
import Roi from "../images/science/roi-card.svg"
import Scale from "../images/science/scale-card.svg"
import "./science.module.scss"
import CTA from "../components/cta"

const Science = () => {
  const cardsDataScience = [
    {
      title: "DAAP",
      desc:
        "Our simple mantra; “turn up and perform”, is encapsulated in the DAAP model - the Desire and Ability to Attend and Perform. You may not visibly see DAAP in the reports, but it is inherent in all aspects of SHAPE - all SHAPE Explorers, and all the questions within them, impact one or more areas of DAAP.        ",
      cardImg: DAAP,
      color: "dark-blue",
    },
    {
      title: "DASS",
      desc:
        "Stress, anxiety and depression materially impact work performance. Get the most accurate view of mental health of your people. Focus where the risk is highest. Help through precision support. Improve the interventions you provide.",
      cardImg: DASS,
      color: "purple",
    },
    {
      title: "AIM",
      desc:
        "The future of work is now. Hybrid is here to stay. The Autonomy in Isolation Model (AIM) evaluates the ability of employees to remain productive at home and how well they deal with physical separation from the office and their colleagues.        ",
      cardImg: AIM,
      color: "green",
    },
    {
      title: "Your Network",
      desc:
        "Build your organisational structure from the ground up, one employee at a time. Who do you report to and who reports to you? Using AI, we chart the web of connections and provide a precise view of your organisation, both from a reporting and an intervention perspective. Know exactly where to make a difference.",
      cardImg: Network,
      color: "sea-blue",
    },
    {
      title: "ROI",
      desc:
        "Each SHAPE point has serious value. When companies invest to improve their SHAPE scores, improvements in critical corporate measures occur: reduced absenteeism, increased productivity, reduced staff turnover, and reduced claims/illness risk. Powered by SHAPE’s proprietary Value of Benefit model, it quantifies the time and money ROI possible by improving every SHAPE point.​",
      cardImg: Roi,
      color: "sea-blue",
    },
    {
      title: "Scale",
      desc:
        "It’s difficult to train algorithms that scale from one person, to teams, to the whole company. We did it! This is where our decades of experience in clinical, data and mathematical sciences comes to the fore. Not to mention survey science which demands unbiased quality questions that stand up to the best academic rigour. This is why you see PhD power in SHAPE leadership. ​        ",
      cardImg: Scale,
      color: "dark-blue",
    },
  ]
  return (
    <Layout>
      <SEO title="Science" keywords={[`gatsby`, `application`, `react`]} />
      <div styleName="page-container">
        <div styleName="science-cover fadeIn">
          <div styleName="science-text-cover">
            <h1>Reliance on science​</h1>
            <p>
              SHAPE enables simple understanding of complex employee data. Based
              on proprietary scientific models, we set a new standard through
              advanced scientific research and AI. With SHAPE’s results, you’re
              in good hands. You can safely make the big decisions you need to
              succeed. ​
            </p>
          </div>
          <div styleName="science-hero-img-wrapper">
            <img src={ScienceCover} alt="science-cover" />
          </div>
        </div>
        <div styleName="science-cards-parent">
          {cardsDataScience.map((item, index) => (
            <ScienceCards key={`${item.title} ${index}`} data={item} />
          ))}
        </div>
      </div>
      <CTA />
    </Layout>
  )
}

export default Science
