import React from "react"
import "./science-cards.module.scss"

const ScienceCards = ({ data }) => {
  return (
    <div styleName={`card-parent ${data.color}`}>
      <img src={data.cardImg} alt={data.title} />
      <h2>{data.title}</h2>
      <p>{data.desc}</p>
    </div>
  )
}

export default ScienceCards
